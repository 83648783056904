import { Trans } from "@lingui/macro";
import React, { useState } from "react";

const testimonials = [
  {
    date: <Trans>May 8, 2024</Trans>,
    name: <Trans>Mary Jane</Trans>,
    position: <Trans>CEO at</Trans>,
    company: <Trans>Company_Name</Trans>,
    title: <Trans>Outstanding Service</Trans>,
    text: (
      <Trans>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci
        lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.
        Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna
        pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus
        arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Trans>
    ),
    image: "/landing/testim-1.png",
  },
  {
    date: <Trans>May 9, 2024</Trans>,
    name: <Trans>John Doe</Trans>,
    position: <Trans>CEO at</Trans>,
    company: <Trans>Another_Company</Trans>,
    title: <Trans>Highly Recommend</Trans>,
    text: (
      <Trans>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sed magna eget nibh in
        turpis.
      </Trans>
    ),
    image: "/landing/testim-2.png",
  },
];

const TestimonialsSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className="relative min-h-[620px] bg-[#F9FAFB] px-4 sm:px-8">
      <img
        src="/landing/testimonials-icons.png" // Adjust the path accordingly
        alt="Testimonials Background"
        className="absolute inset-0 w-full object-cover"
      />
      <div className="relative mx-auto max-w-4xl pt-12 sm:pt-20">
        <h2 className="relative z-10 mb-6 text-center text-2xl font-bold text-[#0182A9] sm:text-3xl">
          <Trans>What Our Clients Say About Us</Trans>
        </h2>
        <div className="relative overflow-hidden rounded-lg">
          <div
            className="mt-4 flex transition-transform duration-300"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {testimonials.map((testimonial, index) => (
              <div key={index} className="min-w-full p-4 sm:max-w-sm">
                <div className="flex flex-col items-center rounded-lg bg-white shadow-md sm:flex-row">
                  <img
                    src={testimonial.image}
                    alt={`Client ${index + 1}`}
                    className="w-2/3 rounded-t-lg sm:w-1/4 sm:rounded-l-md sm:rounded-t-none"
                  />
                  <div className="p-4 sm:p-5 sm:pl-8">
                    <p className="mt-2 text-xs text-gray-500">{testimonial.date}</p>
                    <h3 className="mt-2 text-lg font-semibold text-[#3F444F] sm:text-xl">
                      {testimonial.title}
                    </h3>
                    <p className="mt-2 text-sm text-gray-700">{testimonial.text}</p>
                    <p className="mt-6 text-right text-xs">
                      <span className="font-bold">{testimonial.name}</span> /{" "}
                      <span className="text-[#3F444F]">{testimonial.position}</span>{" "}
                      <span className="text-[#0182A9]">{testimonial.company}</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Navigation Arrows */}
        <div className="absolute bottom-[-2] z-10 flex w-full items-center justify-between px-4 sm:inset-x-0 sm:px-0">
          <button className="cursor-pointer p-2" onClick={prevSlide}>
            <img src="/landing/chevron-down.svg" alt="Previous" />
          </button>
          <button className="cursor-pointer p-2" onClick={nextSlide}>
            <img src="/landing/chevron-down.svg" alt="Next" className="rotate-180" />
          </button>
        </div>
        {/* Dots Navigation */}
        <div className="z-10 mt-6 flex justify-center">
          {testimonials.map((_, index) => (
            <button
              key={index}
              className={`mx-1 size-2 rounded-full ${
                currentIndex === index ? "bg-[#0182A9]" : "bg-gray-300"
              }`}
              onClick={() => {
                setCurrentIndex(index);
              }}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSlider;
