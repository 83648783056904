import { Trans } from "@lingui/macro";
import React from "react";
import { useWindowSize } from "usehooks-ts";

const TestimonialsSection = ({ isAboutUs }: { isAboutUs?: boolean }) => {
  const { width } = useWindowSize(); // Get the current window size

  // Determine the image source based on the screen size
  const framesImageSrc = width < 640 ? "/landing/Frame-26.png" : "/landing/Frame-26-mobile.png";

  return (
    <>
      <section className="mb-10 flex h-[400px] flex-col items-center justify-center bg-[#F9FAFB] text-center">
        <img
          src={isAboutUs ? "/landing/ph_quotes-bold-blue.svg" : "/landing/ph_quotes-bold.svg"}
          alt="testimonials"
          draggable="false"
          width="48"
        />
        <p className="mt-4 max-w-3xl text-2xl font-black text-[#111928] max-sm:mx-2 max-sm:text-xl">
          <Trans>
            {/* eslint-disable-next-line lingui/text-restrictions */}
            <span>"Workinglife is powered by 123test.com. With over 20 years of </span>
            {/* eslint-disable-next-line lingui/text-restrictions */}
            <span> experience a true powerhouse in offering free career tools and tests."</span>
          </Trans>
        </p>
        <div className="mt-8 flex items-center space-x-4">
          <div className="size-6 rounded-full bg-gray-300"></div>
          <p className="text-[16px] font-black text-[#111928]">
            <Trans>Dr. Edwin van Thiel</Trans>
          </p>
          <p className="text-[16px] font-black text-[#111928]"> / </p>
          <span className="text-sm text-[#3F444F]">
            <Trans>CEO at 123test</Trans>
          </span>
        </div>
      </section>

      {!isAboutUs && (
        <section className="bg-white">
          <img src={framesImageSrc} alt="Landing" draggable="false" className="mb-10" />
          <div className="mb-10"></div>
        </section>
      )}
    </>
  );
};

export default TestimonialsSection;
