import { t, Trans } from "@lingui/macro";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
export const DashboardStartPage = () => {
  // Fetch dashboard data (e.g., stats, recent activities)
  useEffect(() => {
    // Fetch data here if needed
  }, []);

  return (
    <div className="space-y-6 p-6">
      <Helmet>
        <title>
          {t`Assessments`} - {t`WorkingLife`}
        </title>
      </Helmet>

      {/* Welcome Message */}
      <section>
        <h1 className="mb-6 text-left text-3xl font-bold">
          <Trans>Welcome!</Trans>
        </h1>
      </section>
    </div>
  );
};
