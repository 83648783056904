import { Trans } from "@lingui/macro";
import React from "react";

const AboutUsSection = () => {
  return (
    <div className=" mr-10 bg-white">
      <header className="ml-3 grid grid-cols-1 bg-white p-8 text-left md:ml-0 md:grid-cols-2">
        {/* Left content: Text and buttons */}
        <div className="flex max-w-xl  flex-col justify-center md:ml-24">
          <h1 className="text-3xl font-black md:text-6xl">
            <Trans>
              <span className="bg-gradient-to-r from-[#DB7B03] to-[#FFEFDA] bg-clip-text text-transparent">
                About us
              </span>{" "}
            </Trans>
          </h1>
          <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
            <Trans>
              Workinglife.com is part of 123test.com, a global leader in the development of online
              career tools and psychometric assessment tests.
            </Trans>
          </div>
          <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
            <Trans>
              We have over 20 years of specialized experience and provided products and services to
              more than a quarter of a billion individuals already.
            </Trans>
          </div>
          <div className="mt-10 text-lg font-normal leading-normal text-[#3F444F]">
            <b>
              <Trans>Thank you for visiting!</Trans>
            </b>
          </div>
        </div>
        {/* Right content: Image */}
        <div className="ml-auto mt-8 hidden items-center justify-center md:block lg:flex">
          <img src="/landing/about-us.png" alt="Landing" width="448px" height="450px" />
        </div>
      </header>
    </div>
  );
};

export default AboutUsSection;
