import { Trans } from "@lingui/macro";
import React from "react";

const ContactUs = () => {
  return (
    <div className="flex h-[250px] flex-col items-center justify-center bg-white">
      <h3 className="text-6xl font-bold">
        <span className="bg-gradient-to-r from-[#DB7B03] to-[#FFEFDA] bg-clip-text text-transparent">
          <Trans>Contact us</Trans>
        </span>
      </h3>
      <p className="mt-4 max-w-lg text-center text-lg text-gray-700">
        <Trans>
          For any questions on how things work, please refer to the FAQ. For any other remarks,
          compliments, or inquiries, send an email to{" "}
          <a href="mailto:info@workinglife.com" className="font-bold text-[#0182A9]">
            info@workinglife.com
          </a>
          .
        </Trans>
      </p>
    </div>
  );
};

export default ContactUs;
