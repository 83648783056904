import "tailwindcss/tailwind.css";

import { t, Trans } from "@lingui/macro";
import { Eye, Plus, SpinnerGap } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { useActiveAssessmentsMin } from "@/client/services/assessment/active-assessments-min";
import { useAssessments } from "@/client/services/assessment/assessment";
import { AssessmentTypes } from "@/client/services/assessment/constants/main";
import { useCreateAssessment } from "@/client/services/assessment/create";

import { BaseCard } from "../cover-letters/_layouts/grid/_components/base-card";

const AssessmentSection = ({
  title,
  testId,
  testImg,
  testImgSvg,
  handleStartTest,
  creatingAssessment,
  alt,
  handleSeeResult,
}: any) => (
  <div>
    <h2 className="mb-8 text-xl font-semibold">{title}</h2>
    <BaseCard className="max-w-[350px] ">
      {testId ? (
        <>
          {testImg && (
            <button
              className="flex w-full items-center justify-center space-x-2 bg-slate-300 px-4 py-2 text-white transition duration-300 hover:bg-slate-400"
              onClick={handleSeeResult}
            >
              <Eye size={20} />
              <span>
                {" "}
                <Trans>View free test result</Trans>
              </span>
            </button>
          )}
          {testImg === null ? (
            <div className="flex items-center space-x-3 text-gray-600">
              <SpinnerGap size={32} className="animate-spin text-blue-500" />
              <span className="text-lg font-semibold">
                <Trans>Assessment in progress</Trans>
              </span>
            </div>
          ) : (
            <object data={testImgSvg} type="image/svg+xml">
              <img
                src={testImg}
                className="mb-4 h-[450] w-[350]"
                alt={`${alt} result`}
                onClick={handleSeeResult}
              />
            </object>
          )}
          <button
            className="w-full bg-slate-300 px-4 py-2 text-white transition duration-300 hover:bg-slate-400"
            onClick={handleStartTest}
          >
            {testImg === null ? <Trans>Continue</Trans> : <Trans>Retake</Trans>} {title}{" "}
            <Trans>assessment test</Trans>
          </button>
        </>
      ) : (
        <>
          <Plus size={64} weight="thin" className="my-10" />
          <button
            className="w-full  bg-green-500 px-4 py-2 text-white transition duration-300 hover:bg-green-600"
            disabled={creatingAssessment}
            onClick={handleStartTest}
          >
            {creatingAssessment ? (
              <Trans>Starting...</Trans>
            ) : (
              <Trans>Start {title} assessment test</Trans>
            )}
          </button>
        </>
      )}
    </BaseCard>
  </div>
);
const handleSeeTestResult = (token: string | undefined) => {
  console.log(token);
  if (!token) {
    return;
  }
  const url = `https://${appApiAssessUrl}/?cmd=report&token=${token}`;
  window.open(url, "_self");
};

export const AssessmentsPage = () => {
  const [discTestId, setDiscTestId] = useState<string | null>(null);
  const [workValuesTestId, setWorkValuesTestId] = useState<string | null>(null);
  const [discTestImg, setDiscTestImg] = useState<string | null>(null);
  const [workValuesTestImg, setWorkValuesTestImg] = useState<string | null>(null);
  const [discTestImgSvg, setDiscTestImgSvg] = useState<string | null>(null);
  const [workValuesTestImgSvg, setWorkValuesTestImgSvg] = useState<string | null>(null);

  const { createAssessment, loading: creatingAssessment, error } = useCreateAssessment();
  const { assessments } = useAssessments();
  const { discAssessment: activeDiscAssessment, workValuesAssessment: activeWorkValuesAssessment } =
    useActiveAssessmentsMin();

  // Check if the assessments have been taken
  useEffect(() => {
    if (Array.isArray(assessments)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      const discAssessment = assessments.find((a) => a.instrumentId === AssessmentTypes.DISC);
      const workValuesAssessment = assessments.find(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        (a) => a.instrumentId === AssessmentTypes.WORK_VALUES,
      );

      setDiscTestId(discAssessment ? discAssessment.id : null);
      setWorkValuesTestId(workValuesAssessment ? workValuesAssessment.id : null);
    }
    if (activeDiscAssessment?.reportAccessCodeStandard1_svg) {
      setDiscTestImg(
        `https://${appApiAssessUrl}/svg2png/?uuid=${activeDiscAssessment.reportAccessCodeStandard1_svg}&apihost=${appApi123testUrl}`,
      );
      setDiscTestImgSvg(
        `https://${appApi123testUrl}/v2/report/${activeDiscAssessment.reportAccessCodeStandard1_svg}`,
      );
    }
    if (activeWorkValuesAssessment?.reportAccessCodeStandard1_svg) {
      setWorkValuesTestImg(
        `https://${appApiAssessUrl}/svg2png/?uuid=${activeWorkValuesAssessment.reportAccessCodeStandard1_svg}&apihost=${appApi123testUrl}`,
      );
      setWorkValuesTestImgSvg(
        `https://${appApi123testUrl}/v2/report/${activeWorkValuesAssessment.reportAccessCodeStandard1_svg}`,
      );
    }
  }, [assessments, activeDiscAssessment, activeWorkValuesAssessment]);

  const handleStartDiscTest = async () => {
    try {
      const newAssessment = await createAssessment(AssessmentTypes.DISC);
      setDiscTestId(newAssessment.id);
    } catch (error) {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      console.error("Error creating DISC assessment:", error);
    }
  };

  const handleStartWorkValuesTest = async () => {
    try {
      const newAssessment = await createAssessment(AssessmentTypes.WORK_VALUES);
      setWorkValuesTestId(newAssessment.id);
    } catch (error) {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      console.error("Error creating Work Values assessment:", error);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <Helmet>
        <title>
          {t`Assessments`} - {t`WorkingLife`}
        </title>
      </Helmet>
      <h1 className="mb-6 text-left text-3xl font-bold">
        <Trans>Assessments</Trans>
      </h1>
      <p className="mb-8 max-w-xl text-gray-600">
        <Trans>
          <div className="mb-3">Enjoy these free career assessments provided by 123test.com!</div>
          When you take them you get instant free results and graphical results become available for
          your Personality Snapshots. You can retake assessments at any time, but that replaces the
          previous results also in existing personality snapshots.
          <div className="mt-3">
            Assessments typically take ten to twelve minutes and must be completed in a single go.
          </div>
        </Trans>
      </p>
      <div className="grid grid-cols-1  md:grid-cols-2">
        <AssessmentSection
          title={<Trans>DISC Personality Test</Trans>}
          alt="DISC Personality Test"
          testId={discTestId}
          testImg={discTestImg}
          testImgSvg={discTestImgSvg}
          handleStartTest={handleStartDiscTest}
          creatingAssessment={creatingAssessment}
          emptyImg="/assets/disc_cloud2.png"
          handleSeeResult={() => {
            handleSeeTestResult(activeDiscAssessment?.apiId);
          }}
        />
        <AssessmentSection
          title={<Trans>Work Values Test</Trans>}
          alt="Work Values Test"
          testId={workValuesTestId}
          testImg={workValuesTestImg}
          testImgSvg={workValuesTestImgSvg}
          handleStartTest={handleStartWorkValuesTest}
          creatingAssessment={creatingAssessment}
          emptyImg="/assets/work-values.jpg"
          handleSeeResult={() => {
            handleSeeTestResult(activeWorkValuesAssessment?.apiId);
          }}
        />
      </div>
      {error && <p className="mt-4 text-red-500">{error.message}</p>} {/* Display error message */}
    </div>
  );
};
