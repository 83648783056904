import { Trans } from "@lingui/macro";
import React from "react";

import Button from "./buttons/button-kit";

const HeaderSection = () => {
  return (
    <div className=" bg-white ">
      <header className="mb-10 ml-3 grid grid-cols-1 bg-white p-8 text-left md:ml-0 md:grid-cols-2">
        {/* Left content: Text and buttons */}
        <div className="flex flex-col justify-center md:ml-24">
          <h1 className="text-3xl font-black text-[#111928] md:text-6xl">
            <Trans>
              Free Forever{" "}
              <span className="bg-gradient-to-r from-[#DB7B03] to-[#FFEFDA] bg-clip-text text-transparent">
                Career Tools
              </span>{" "}
            </Trans>
          </h1>
          <p className="mt-4 text-lg text-[#3F444F]">
            <Trans>
              Standing with you from graduation to retirement! Use Personality Snapshots to make
              your job application truly authentic. It's highly appreciated by hiring managers as it
              shows human effort and genuine interest. Increase the quality of your working life and
              that of job applications specifically!
            </Trans>
          </p>
          <div className="mt-6 space-y-4 md:space-x-4">
            <Button bgColor="bg-[#0182A9]" textColor="text-white" navigateTo="/auth/register">
              <Trans>Sign Up</Trans>
            </Button>
            <Button border borderColor="#E5E7EB" sectionId="faq">
              <Trans>FAQ</Trans>
            </Button>
          </div>
        </div>
        {/* Right content: Image */}
        <div className="mt-8 hidden items-center justify-center md:block lg:flex">
          <img src="/landing/unsplash_4YzrcDNcRVg.svg" alt="Landing" width="448px" height="450px" />
        </div>
        <div className="min-h-10"></div>
      </header>
    </div>
  );
};

export default HeaderSection;
