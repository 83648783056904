import { Trans } from "@lingui/macro";
import React, { useState } from "react";

const FAQSection = ({ isAboutUs }: { isAboutUs: boolean }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const faqs = [
    {
      question: <Trans>Can I use Workinglife in job seeking?</Trans>,
      answer: (
        <Trans>
          Generally, it is accepted to use Workinglife in job seeking, as long as it is comfortable
          for you. With that being said, feel free to use our CV Templates. Find out more
          information by reading the license.
        </Trans>
      ),
    },
    {
      question: <Trans>Can I export my CV?</Trans>,
      answer: <Trans>Yes, exporting your CV is easy and available in multiple formats.</Trans>,
    },
    {
      question: <Trans>Can I use Workinglife for commercial purposes?</Trans>,
      answer: (
        <Trans>
          Using Workinglife for commercial purposes is allowed under specific conditions. Please
          review our terms.
        </Trans>
      ),
    },
    {
      question: <Trans>How do you support recruiters?</Trans>,
      answer: (
        <Trans>
          We offer a range of tools and features specifically designed to help recruiters find the
          right talent quickly and efficiently.
        </Trans>
      ),
    },
  ];

  const toggleFAQ = (index: any) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section
      className={`p-8 ${
        isAboutUs
          ? "bg-gradient-to-r from-[#CFEAFA] to-[#0182A9]"
          : "bg-gradient-to-r from-[#FFEFDA] to-[#DB7B03]"
      }`}
      id="faq"
    >
      <div className="mx-auto gap-8 rounded-2xl bg-white p-8 px-6 md:px-32 lg:w-[1200px]">
        <div className="mx-auto lg:w-[695px]">
          <h2 className="mb-10 text-center text-3xl font-bold text-[#111928]">
            <Trans>Frequently Asked Questions</Trans>
          </h2>
          {faqs.map((faq, index) => (
            <div key={index} className="mt-4">
              <div
                className="flex cursor-pointer items-center justify-between text-[#111928]"
                onClick={() => {
                  toggleFAQ(index);
                }}
              >
                <h3 className="flex-1 text-sm font-semibold">{faq.question}</h3>
                {/* Render arrow icon based on the expanded state */}
                {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
                <span className="text-gray-600 ">
                  {expandedIndex === index ? (
                    <i className="ph ph-caret-up size-6" /> // Caret up icon
                  ) : (
                    <i className="ph ph-caret-down size-6" /> // Caret down icon
                  )}
                </span>
              </div>
              <div className="mt-2 border-b border-gray-300" /> {/* Bottom border line */}
              {expandedIndex === index && (
                <p className="mt-2 text-xs text-[#111928]">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
