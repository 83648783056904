import { Trans } from "@lingui/macro";
import React from "react";

const TermsAndConditions = () => (
  <div className="mx-auto my-10 max-w-2xl rounded-lg bg-gray-100 p-6 shadow-md">
    <h1 className="mb-4 text-3xl font-semibold text-[#0182A9]">
      <Trans>General Terms and Conditions of workinglife</Trans>
    </h1>
    <p className="mb-4 text-gray-600">
      {/* eslint-disable-next-line lingui/text-restrictions */}
      <Trans>
        Workinglife is a tradename of 123test BV which uses the official terms and conditions of the
        Dutch organization for online shopping "Thuiswinkel.org" and adheres strictly to its rules
        of conduct.
      </Trans>
    </p>
    <p className="mb-4 text-gray-600">
      <Trans>
        If you have any questions regarding our services and products, please let us know by{" "}
        <a href="/about/" className="text-blue-500 underline hover:text-[#0182A9]">
          contacting us
        </a>
        .
      </Trans>
    </p>
    <p className="mb-6 text-gray-600">
      <Trans>
        Please note that for all non-European Union currencies such as US dollars, the VAT rate
        charged is 0%.
      </Trans>
    </p>
    <h2 className="mb-3 text-2xl font-semibold text-[#0182A9]">
      <Trans>General Terms and Conditions of Thuiswinkel.org</Trans>
    </h2>
    <p className="mb-6 text-gray-600">
      {/* eslint-disable-next-line lingui/text-restrictions */}
      <Trans>
        These General Terms and Conditions of Thuiswinkel.org were drawn up in consultation with the
        Consumers’ Association within the framework of the Self-Regulation Coordination Group (CZ)
        of the Social and Economic Council and will become operable as of June 1st, 2014.
      </Trans>
    </p>
    <h2 className="mb-3 text-2xl font-semibold text-[#0182A9]">
      <Trans>Index</Trans>
    </h2>
    <ul className="list-inside list-disc space-y-2 text-gray-700">
      <li>
        <Trans>Article 1 – Definitions</Trans>
      </li>
      <li>
        <Trans>Article 2 – Identity of the trader</Trans>
      </li>
      <li>
        <Trans>Article 3 – Applicability</Trans>
      </li>
      <li>
        <Trans>Article 4 – The offer</Trans>
      </li>
      <li>
        <Trans>Article 5 – The contract</Trans>
      </li>
      <li>
        <Trans>Article 6 – Right of withdrawal</Trans>
      </li>
      <li>
        <Trans>Article 7 – Customers' obligations in case of withdrawal</Trans>
      </li>
      <li>
        <Trans>
          Article 8 – Customers who exercise their right of withdrawal and the costs involved
        </Trans>
      </li>
      <li>
        <Trans>Article 9 – Traders' obligations in case of withdrawal</Trans>
      </li>
      <li>
        <Trans>Article 10 – Precluding the right of withdrawal</Trans>
      </li>
      <li>
        <Trans>Article 11 – The price</Trans>
      </li>
      <li>
        <Trans>Article 12 – Contract fulfilment and extra guarantee</Trans>
      </li>
      <li>
        <Trans>Article 13 – Delivery and implementation</Trans>
      </li>
      <li>
        <Trans>
          Article 14 – Extended duration transactions: duration, termination and prolongation
        </Trans>
      </li>
      <li>
        <Trans>Article 15 – Payment</Trans>
      </li>
      <li>
        <Trans>Article 16 – Complaints procedure</Trans>
      </li>
      <li>
        <Trans>Article 17 – Disputes</Trans>
      </li>
      <li>
        <Trans>Article 18 – Branch guarantee</Trans>
      </li>
      <li>
        <Trans>Article 19 – Additional or different stipulations</Trans>
      </li>
      <li>
        <Trans>Article 20 – Amendment to the general terms and conditions of Thuiswinkel.org</Trans>
      </li>
    </ul>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 1 – Definitions</Trans>
    </h3>
    <p className="text-gray-700">
      <Trans>The following definitions apply in these terms and conditions:</Trans>
    </p>
    <ol className="list-inside list-decimal space-y-2 text-gray-700">
      <li>
        <strong className="text-[#0182A9]">
          <Trans>Supplementary agreement:</Trans>
        </strong>{" "}
        <Trans>
          an agreement in which a consumer obtains products, digital content and/or services via a
          distance contract, and a trader or a third party delivers these products, digital content
          and/or services in accordance with an agreement between that third party and the trader;
        </Trans>
      </li>
      <li>
        <strong className="text-[#0182A9]">
          <Trans>Withdrawal period:</Trans>
        </strong>{" "}
        <Trans>
          the period within which the consumer can make use of their right of withdrawal;
        </Trans>
      </li>
      <li>
        <strong className="text-[#0182A9]">
          <Trans>Consumer:</Trans>
        </strong>{" "}
        <Trans>
          a natural person whose actions are not carried out for objectives relating to the course
          of a trade, a profession, or a business;
        </Trans>
      </li>
      <li>
        <strong className="text-[#0182A9]">
          <Trans>Day:</Trans>
        </strong>{" "}
        <Trans>calendar day;</Trans>
      </li>
      <li>
        <strong className="text-[#0182A9]">
          <Trans>Digital content:</Trans>
        </strong>{" "}
        <Trans>data that are produced and supplied in digital form;</Trans>
      </li>
      <li>
        <strong className="text-[#0182A9]">
          <Trans>Extended duration transaction:</Trans>
        </strong>{" "}
        <Trans>
          a distance contract that relates to a series of products and/or services, whereby the
          obligation to supply and/or purchase is spread over a period of time;
        </Trans>
      </li>
      <li>
        <strong className="text-[#0182A9]">
          <Trans>Durable medium:</Trans>
        </strong>{" "}
        <Trans>
          every means - including emails - that enables a consumer or trader to store information
          for future use during a period suited to its purpose, ensuring unaltered reproduction;
        </Trans>
      </li>
      <li>
        <strong className="text-[#0182A9]">
          <Trans>Right of withdrawal:</Trans>
        </strong>{" "}
        <Trans>
          the possibility for a consumer to waive a distance contract within the withdrawal period;
        </Trans>
      </li>
      <li>
        <strong className="text-[#0182A9]">
          <Trans>Trader:</Trans>
        </strong>{" "}
        <Trans>
          a natural or legal person who is a member of Thuiswinkel.org and who offers products,
          (access to) digital content, and/or services to consumers from a distance;
        </Trans>
      </li>
      <li>
        <strong className="text-[#0182A9]">
          <Trans>Distance contract:</Trans>
        </strong>{" "}
        <Trans>
          a contract concluded between a trader and a consumer for the distance sale of products,
          digital content, and/or services, using one or more distance communication techniques;
        </Trans>
      </li>
      <li>
        <strong className="text-[#0182A9]">
          <Trans>Model form for right of withdrawal:</Trans>
        </strong>{" "}
        <Trans>
          the European model form for right of withdrawal included in Appendix I of these terms and
          conditions. Not required if the consumer has no right of withdrawal for their order;
        </Trans>
      </li>
      <li>
        <strong className="text-[#0182A9]">
          <Trans>Technique for distance communication:</Trans>
        </strong>{" "}
        <Trans>
          means that can be used for concluding a contract without the necessity of consumer and
          trader being in the same place at the same time.
        </Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 2 – Identity of the trader</Trans>
    </h3>
    <table className="w-full border border-gray-300 text-gray-700">
      <tbody>
        <tr className="border-b border-gray-200">
          <td className="p-2 font-semibold text-[#0182A9]">
            <Trans>Name trader:</Trans>
          </td>
          <td className="p-2">
            <Trans>123test BV</Trans>
          </td>
        </tr>
        <tr className="border-b border-gray-200">
          <td className="p-2 font-semibold text-[#0182A9]">
            <Trans>Registered address:</Trans>
          </td>
          <td className="p-2">
            <Trans>Jonkerbosplein 52, 6534 AB Nijmegen, Netherlands</Trans>
          </td>
        </tr>
        <tr className="border-b border-gray-200">
          <td className="p-2 font-semibold text-[#0182A9]">
            <Trans>Telephone:</Trans>
          </td>
          <td className="p-2">
            <Trans>+31(0)24 2210083</Trans>
          </td>
        </tr>
        <tr className="border-b border-gray-200">
          <td className="p-2 font-semibold text-[#0182A9]">
            <Trans>E-mail address:</Trans>
          </td>
          <td className="p-2">
            <Trans>info@123test.com</Trans>
          </td>
        </tr>
        <tr className="border-b border-gray-200">
          <td className="p-2 font-semibold text-[#0182A9]">
            <Trans>Chamber of Commerce number:</Trans>
          </td>
          <td className="p-2">
            <Trans>27187803</Trans>
          </td>
        </tr>
        <tr>
          <td className="p-2 font-semibold text-[#0182A9]">
            <Trans>VAT identification number:</Trans>
          </td>
          <td className="p-2">
            <Trans>NL817941800B01</Trans>
          </td>
        </tr>
      </tbody>
    </table>
    <p className="mt-4 text-gray-700">
      <Trans>
        Should the activity of the trader be subject to a relevant licensing system: the data of the
        supervising authority;
      </Trans>
    </p>
    <p className="text-gray-700">
      <Trans>Should the trader practice a regulated profession:</Trans>
    </p>
    <ul className="list-inside list-disc space-y-1 text-gray-700">
      <li>
        <Trans>the professional association or organization with which they are affiliated;</Trans>
      </li>
      <li>
        <Trans>
          the professional title, the town/city in the EU or EEA where this was granted;
        </Trans>
      </li>
      <li>
        <Trans>
          a reference to the professional rules that apply in the Netherlands and instructions on
          where and how these professional rules can be accessed.
        </Trans>
      </li>
    </ul>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9] ">
      <Trans>Article 3 – Applicability</Trans>
    </h3>
    <ol className="text-gray-700">
      <li>
        <Trans>
          These general terms and conditions apply to every offer made by a trader and to every
          distance contract that has been realized between an trader and a consumer.
        </Trans>
      </li>
      <li>
        {/* eslint-disable-next-line lingui/text-restrictions */}
        <Trans>
          Prior to the conclusion of a distance contract, the text of these general terms and
          conditions will be made available to the consumer. If this is not reasonably possible, the
          trader will indicate, before the distance contract is concluded, in what way the general
          terms and conditions are available for inspection at the trader’s premises and that they
          will be sent free of charge to the consumer, as quickly as possible, at the consumer’s
          request.
        </Trans>
      </li>
      <li>
        <Trans>
          If the distance contract is concluded electronically, then, contrary to the previous
          paragraph, and before the distance contract is concluded, the consumer will be provided
          with the text of these general terms and conditions electronically, in such a way that the
          consumer can easily store them on a durable data carrier. If this is not reasonably
          possible, then before concluding the distance contract, the trader will indicate where the
          general terms and conditions can be inspected electronically and that at his request they
          will be sent to the consumer free of charge, either electronically or in some other way.
        </Trans>
      </li>
      <li>
        <Trans>
          In cases where specific product or service-related terms and conditions apply in addition
          to these general terms and conditions, the second and third paragraphs apply by analogy
          and the consumer can always invoke the applicable condition that is most favorable to him
          in the event of incompatible general terms and conditions.
        </Trans>
      </li>
    </ol>
    <h3 className="mt-4 text-lg font-semibold text-[#0182A9] ">
      <Trans>Article 4 – The offer</Trans>
    </h3>
    <ol className="text-gray-700">
      <li>
        <Trans>
          If an offer is subject to a limited period of validity or is made subject to conditions,
          this will be explicitly mentioned in the offer.
        </Trans>
      </li>
      <li>
        <Trans>
          The offer contains a complete and accurate description of the products, digital content
          and/or services being offered. The description is sufficiently detailed to enable the
          consumer to make a proper assessment of the offer. If the trader makes use of
          illustrations, these will be a true representation of the products and/or services being
          offered. The trader is not bound by obvious errors or mistakes in the offer.
        </Trans>
      </li>
      <li>
        <Trans>
          Every offer contains information that makes it clear to the consumer what rights and
          obligations are related to the acceptance of the offer.
        </Trans>
      </li>
    </ol>
    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 5 – The contract</Trans>
    </h3>
    <ol className="text-gray-700">
      <li>
        <Trans>
          The contract will be concluded, subject to that which is stipulated in paragraph 4, at the
          moment at which the consumer accepts the offer and the conditions thereby stipulated have
          been fulfilled.
        </Trans>
      </li>
      <li>
        <Trans>
          If the consumer has accepted the offer electronically, the trader will immediately confirm
          receipt of acceptance of the offer electronically. The consumer can dissolve the contract
          as long as this acceptance has not been confirmed by the trader.
        </Trans>
      </li>
      <li>
        <Trans>
          If the contract is concluded electronically, the trader will take suitable technical and
          organizational measures to secure the electronic transfer of data and he will ensure a
          safe web environment. If the consumer is able to pay electronically, the trader will take
          suitable security measures.
        </Trans>
      </li>
      <li>
        {/* eslint-disable-next-line lingui/text-restrictions */}
        <Trans>
          The trader may obtain information – within statutory frameworks – about the consumer’s
          ability to fulfill his payment obligations, as well as about facts and factors that are
          important for the responsible conclusion of the distance contract. If that research gives
          the trader proper grounds for declining to conclude the contract, then he has a right,
          supported by reasons, to reject an order or application or to bind its implementation to
          special conditions.
        </Trans>
      </li>
      <li>
        <Trans>
          The trader will send to a consumer, at the latest when delivering a product, service or
          digital content, the following information, in writing, or in such a way that the consumer
          can store it on an accessible durable medium:
        </Trans>

        <ol type="a">
          <li>
            {/* eslint-disable-next-line lingui/text-restrictions */}
            <Trans>
              the office address of the trader’s business location where the consumer can lodge
              complaints;
            </Trans>
          </li>
          <li>
            <Trans>
              the conditions under which the consumer can make use of the right of withdrawal and
              the method for doing so, or a clear statement relating to preclusion from the right of
              withdrawal;
            </Trans>
          </li>
          <li>
            <Trans>information on guarantees and existing after-sales service;</Trans>
          </li>
          <li>
            <Trans>
              the price, including all taxes on the product, service or digital content; the costs
              of delivery insofar as applicable, and the method of payment, delivery or implementing
              the distance contract;
            </Trans>
          </li>
          <li>
            <Trans>
              the requirements for terminating the contract, if the duration of the contract exceeds
              one year or if it is indefinite.
            </Trans>
          </li>
          <li>
            <Trans>
              if the consumer has a right of withdrawal, the model form for right of withdrawal.
            </Trans>
          </li>
        </ol>
      </li>
      <li>
        <Trans>
          In case of an extended duration contract, the stipulation in the previous paragraph
          applies only to the first delivery.
        </Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 6 – Right of withdrawal</Trans>
    </h3>
    <p className="text-gray-700">
      <i>
        <Trans>Upon delivery of products:</Trans>
      </i>
    </p>
    <ol className="text-gray-700">
      <li>
        <Trans>
          When purchasing products, a consumer has the right to dissolve a contract, without giving
          reasons, during a period of at least 14 days. The trader is allowed to ask a consumer for
          the reason of this dissolution, but the consumer is under no obligation to state his/her
          reason(s).
        </Trans>
      </li>
      <li>
        <Trans>
          The period stipulated in para. 1 commences on the day after the product was received by
          the consumer, or a third party designated by the consumer, who is not the transporting
          party, or:
        </Trans>
      </li>
      <ol type="a" className="text-gray-700">
        <li>
          <Trans>
            if the consumer has ordered several products: the day on which the consumer, or a third
            party designated by the consumer, received the last product. The trader may refuse a
            single order for several products with different delivery dates, provided he clearly
            informed the consumer of this prior to the ordering process;
          </Trans>
        </li>
        <li>
          <Trans>
            if the delivery of a product involves different deliveries or parts: the day on which
            the consumer, or a third party designated by the consumer, received the last delivery or
            the last part;
          </Trans>
        </li>
        <li>
          <Trans>
            with contracts for the regular delivery of products during a given period: the day on
            which the consumer, or a third party designated by the consumer, received the last
            product.
          </Trans>
        </li>
      </ol>
    </ol>
    <p className="text-gray-700">
      <i>
        <Trans>
          Upon delivery of services and digital content that is not supplied on a material medium:
        </Trans>
      </i>
    </p>
    <ol className="text-gray-700">
      <li>
        <Trans>
          A consumer has the right to dissolve a contract, without giving reasons, for the supply of
          digital content that is not supplied on a material medium during a period of at least
          fourteen days. The trader is allowed to ask a consumer for the reason of this dissolution,
          but the consumer is under no obligation to state his/her reasons.
        </Trans>
      </li>
      <li>
        <Trans>
          The period stipulated in para. 3 commences on the day after the contract was concluded.
        </Trans>
      </li>
    </ol>
    <p className="text-gray-700">
      <i>
        <Trans>
          Extended withdrawal period for products, services and digital content that is not supplied
          on a material medium in the event a consumer was not informed about the right of
          withdrawal:
        </Trans>
      </i>
    </p>
    <ol className="text-gray-700">
      <li>
        <Trans>
          if the trader did not provide the consumer with the statutorily obligatory information
          about the right of withdrawal or if the model form was not provided, the withdrawal period
          ends twelve months after the end of the originally stipulated withdrawal period based on
          the previous paragraphs of this article;
        </Trans>
      </li>
      <li>
        <Trans>
          if the trader provided the consumer with the information referred to in the previous
          paragraph within twelve months of the commencing date of the original withdrawal period,
          the withdrawal period shall end 14 days after the day on which the consumer received the
          information.
        </Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 7 – Consumers' obligations during the withdrawal period</Trans>
    </h3>
    <ol className="text-gray-700">
      <li>
        <Trans>
          During the withdrawal period, the consumer shall treat the product and its packaging with
          care. He shall only unpack or use the product in as far as necessary in order to assess
          the nature, characteristics and efficacy of the product. The point of departure here is
          that the consumer may only handle and inspect the product in the same way that he would be
          allowed in a shop.
        </Trans>
      </li>
      <li>
        <Trans>
          The consumer is only liable for the product's devaluation that is a consequence of his
          handling the product other than as permitted in para. 1.
        </Trans>
      </li>
      <li>
        <Trans>
          The consumer is not liable for the product's devaluation if the trader did not provide him
          with all the statutorily obligatory information about the right of withdrawal before the
          contract was concluded.
        </Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>
        Article 8 – Consumers who exercise their right of withdrawal and the costs involved
      </Trans>
    </h3>
    <ol className="text-gray-700">
      <li>
        <Trans>
          A consumer who wants to exercise his right of withdrawal shall report this to the trader,
          within the withdrawal period, by means of the model form for right of withdrawal or in
          some other unequivocal way.
        </Trans>
      </li>
      <li>
        <Trans>
          As quickly as possible, but no later than 14 days after the day of reporting as referred
          to in para. 1, the consumer shall return the product, or hand it over to (a representative
          of) the trader. This is not necessary if the trader has offered to collect the product
          himself. The consumer will in any case have complied with the time for returning goods if
          he sends the product back before the withdrawal period has lapsed.
        </Trans>
      </li>
      <li>
        <Trans>
          The consumer returns the product with all relevant accessories, if reasonably possible in
          the original state and packaging, and in accordance with the reasonable and clear
          instructions provided by the trader.
        </Trans>
      </li>
      <li>
        <Trans>
          The risk and the burden of proof for exercising the right of withdrawal correctly and in
          time rest upon the consumer.
        </Trans>
      </li>
      <li>
        <Trans>
          The consumer bears the direct costs of returning the product. If the trader has not
          declared that the consumer shall bear these costs or if the trader indicates a willingness
          to bear these costs himself, then the consumer shall not be liable to bear the costs of
          returning goods.
        </Trans>
      </li>
      <li>
        <Trans>
          If the consumer exercises his right of withdrawal, after first explicitly having asked
          that the service provided or the delivery of gas, water or electricity not prepared for
          sale shall be implemented in a limited volume or a given quantity during the period of
          withdrawal, the consumer shall owe the trader a sum of money that is equivalent to that
          proportion of the contract that the trader has fulfilled at the moment of withdrawal, in
          comparison with fulfilling the contract entirely.
        </Trans>
      </li>
      <li>
        <Trans>
          The consumer shall bear no costs for implementing services or the supply of water, gas or
          electricity not prepared for sale - in a limited volume or quantity - or for delivering
          city central heating, if:
        </Trans>
        <ol type="a" className="pl-5">
          <li>
            <Trans>
              the trader did not provide the consumer with the statutorily obligatory information
              about the right of withdrawal, the costs payable in the event of withdrawal or the
              model form for right of withdrawal, or:
            </Trans>
          </li>
          <li>
            <Trans>
              the consumer did not explicitly ask about the commencement of implementing the service
              or the delivery of gas, water, electricity or city central heating during the period
              of withdrawal.
            </Trans>
          </li>
        </ol>
      </li>
      <li>
        <Trans>
          The consumer shall bear no costs for the entire or partial supply of digital content that
          is not supplied on a material medium, if:
        </Trans>
        <ol type="a" className="pl-5">
          <li>
            <Trans>
              prior to delivery, he did not explicitly agree to commencing fulfilment of the
              contract before the end of the period of withdrawal;
            </Trans>
          </li>
          <li>
            <Trans>
              he did not acknowledge having lost his right of withdrawal upon granting his
              permission;
            </Trans>
          </li>
          <li>
            <Trans>the trader neglected to confirm this statement made by the consumer.</Trans>
          </li>
        </ol>
      </li>
      <li>
        <Trans>
          If a consumer exercises his right of withdrawal, all supplementary agreements are legally
          dissolved.
        </Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 9 – Traders' obligations in a case of withdrawal</Trans>
    </h3>
    <ol className="text-gray-700">
      <li>
        <Trans>
          If the trader makes it possible for a consumer to declare his withdrawal via electronic
          means, then after receiving such a declaration, he sends immediate confirmation of
          receipt.
        </Trans>
      </li>
      <li>
        <Trans>
          The trader reimburses the consumer immediately with all payments, including any delivery
          costs the trader charged for the returned product, though at the latest within 14 days
          after the day on which the consumer reported the withdrawal. Except in cases in which the
          trader has offered to retrieve the product himself, he can postpone refunding until he has
          received the product or until the consumer proves he has returned the product, depending
          on which occurs earlier.
        </Trans>
      </li>
      <li>
        <Trans>
          For any reimbursement, the trader will use the same payment method that was initially used
          by the consumer, unless the consumer agrees to another method. Reimbursement is free of
          charge for the consumer.
        </Trans>
      </li>
      <li>
        <Trans>
          If the consumer chose an expensive method of delivery in preference to the cheapest
          standard delivery, the trader does not have to refund the additional costs of the more
          expensive method.
        </Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 10 – Precluding the right of withdrawal</Trans>
    </h3>
    <p className="text-gray-700">
      <Trans>
        The trader can preclude the right of withdrawal for the following products and services, but
        only if the trader stated this when making the offer, or at least in good time prior to
        conclusion of the contract:
      </Trans>
    </p>
    <ol className="list-decimal pl-6 text-gray-700">
      <li>
        <Trans>
          Products or services whose prices are subject to fluctuations on the financial market over
          which the trader has no influence and which can occur within the period of withdrawal;
        </Trans>
      </li>
      <li>
        <Trans>
          Contracts concluded during a public auction. A public auction is defined as a sales method
          whereby a trader offers products, digital content and/or services at an auction, under the
          directions of an auctioneer, and whereby the successful purchaser is obliged to purchase
          the products, digital content and/or services;
        </Trans>
      </li>
      <li>
        <Trans>Service contracts, after full completion of the service, but only if:</Trans>
        <ol type="a" className="list-inside pl-6">
          <li>
            <Trans>
              implementation started with the explicit prior agreement of the consumer; and
            </Trans>
          </li>
          <li>
            <Trans>
              the consumer declared having lost his right or withdrawal as soon as the trader had
              completed the contract in full;
            </Trans>
          </li>
        </ol>
      </li>
      <li>
        <Trans>
          Package travels, package holidays and package tours as referred to in article 7:500 BW and
          contracts on passenger transport;
        </Trans>
      </li>
      <li>
        <Trans>
          Service contracts providing access to accommodation, if the contract already stipulates a
          certain date or period of implementation and other than for the purpose of accommodation,
          the transport of goods, car rental services and catering;
        </Trans>
      </li>
      <li>
        <Trans>
          Contracts relating to leisure activities, if the contract already stipulates a certain
          date or period of implementation;
        </Trans>
      </li>
      <li>
        <Trans>
          Products manufactured according to the consumer's specifications, which were not
          prefabricated and were made based on a consumer's specific choice or decision, or which
          are clearly intended for a specific person;
        </Trans>
      </li>
      <li>
        <Trans>Products subject to rapid decay or with a limited shelf-life;</Trans>
      </li>
      <li>
        <Trans>
          Sealed products that, for reasons relating to the protection of health or hygiene, are
          unsuited to returning and whose seal was broken subsequent to delivery;
        </Trans>
      </li>
      <li>
        <Trans>
          Products that, due to their nature, have been irretrievably mixed with other products;
        </Trans>
      </li>
      <li>
        <Trans>
          Alcoholic drinks whose price was agreed when concluding the contract, but the delivery of
          which can only take place after 30 days, and the actual value of which depends on market
          fluctuations over which the trader has no influence;
        </Trans>
      </li>
      <li>
        <Trans>
          Sealed audio/video-recordings and computer apparatus whose seal was broken after delivery;
        </Trans>
      </li>
      <li>
        <Trans>The delivery of digital content other than on a material medium, but only if:</Trans>
        <ol type="a" className="list-inside pl-6">
          <li>
            <Trans>the delivery commenced with the consumer's explicit prior agreement, and</Trans>
          </li>
          <li>
            <Trans>
              the consumer declared that this implied his having lost his right of withdrawal.
            </Trans>
          </li>
        </ol>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 11 – The price</Trans>
    </h3>
    <ol className="list-decimal pl-6 text-gray-700">
      <li>
        <Trans>
          During the period of validity indicated in the offer, the prices of the products and/or
          services being offered will not be increased, except for price changes in VAT-tariffs.
        </Trans>
      </li>
      <li>
        <Trans>
          Contrary to the previous paragraph, the trader may offer products or services at variable
          prices, in cases where these prices are subject to fluctuations in the financial market
          over which the trader has no influence. The offer must refer to this link with
          fluctuations and the fact that any prices mentioned are recommended prices.
        </Trans>
      </li>
      <li>
        <Trans>
          Price increases within 3 months after the contract was concluded are only permitted if
          they are the result of statutory regulations or stipulations.
        </Trans>
      </li>
      <li>
        <Trans>
          Price increases more than 3 months after the contract was concluded are only permitted if
          the trader stipulated as much and:
        </Trans>
        <ol type="a" className="list-inside pl-6">
          <li>
            <Trans>they are the result of statutory regulations or stipulations; or</Trans>
          </li>
          <li>
            <Trans>
              the consumer is authorized to terminate the contract on the day on which the price
              increase takes effect.
            </Trans>
          </li>
        </ol>
      </li>
      <li>
        <Trans>Prices stated in offers of products or services include VAT.</Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 12 – Contract fulfilment and extra guarantee</Trans>
    </h3>
    <ol className="list-decimal pl-6 text-gray-700">
      <li>
        <Trans>
          The trader guarantees that the products and/or services fulfill the contract, the
          specifications stated in the offer, the reasonable requirements of reliability and/or
          serviceability and the statutory stipulations and/or government regulations that existed
          on the date that the contract was concluded. If agreed the trader also guarantees that the
          product is suited for other than normal designation.
        </Trans>
      </li>
      <li>
        <Trans>
          An extra guarantee arrangement offered by the trader, manufacturer or importer can never
          affect the statutory rights and claims that a consumer can enforce against the trader on
          the grounds of the contract if the trader failed to fulfil his part in the contract.
        </Trans>
      </li>
      <li>
        <Trans>
          An extra guarantee is defined as every commitment of a trader, his supplier, importer or
          manufacturer that grants a consumer rights or claims, in excess of those provided by law,
          for the event that he fails to fulfil his part in the contract.
        </Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 13 – Supply and implementation</Trans>
    </h3>
    <ol className="list-decimal pl-6 text-gray-700">
      <li>
        <Trans>
          The trader will take the greatest possible care when receiving and implementing orders for
          products and when assessing applications for the provision of services.
        </Trans>
      </li>
      <li>
        <Trans>
          The place of delivery is deemed to be the address that the consumer makes known to the
          company.
        </Trans>
      </li>
      <li>
        <Trans>
          Taking into consideration that which is stated in article 4 of these general terms and
          conditions, the company will implement accepted orders with efficient expedition, though
          at the latest within 30 days, unless a longer period of delivery has been agreed. If
          delivery suffers a delay, or if the delivery cannot be implemented, or only partially, the
          consumer will be informed about this at the latest 30 days after the order was placed. In
          this case, the consumer has a right to dissolve the contract free of charge and a right to
          possible damages.
        </Trans>
      </li>
      <li>
        <Trans>
          Following dissolution in accordance with the previous paragraph, the trader refunds the
          consumer immediately the sum he had paid.
        </Trans>
      </li>
      <li>
        <Trans>
          The risk of damage and/or loss of products rests upon the trader up to the moment of
          delivery to the consumer or a representative previously designated by the consumer and
          announced to the trader, unless this has explicitly been agreed otherwise.
        </Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>
        Article 14 – Extended duration transactions: duration, termination and prolongation
      </Trans>
    </h3>
    <p className="italic text-gray-700">
      <Trans>Termination</Trans>
    </p>
    <ol className="list-decimal pl-6 text-gray-700">
      <li>
        <Trans>
          The consumer has a right at all times to terminate an open-ended contract that was
          concluded for the regular supply of products (including electricity) or services, subject
          to the agreed termination rules and a period of notice that does not exceed one month.
        </Trans>
      </li>
      <li>
        <Trans>
          The consumer has a right at all times to terminate a fixed-term contract that was
          concluded for the regular supply of products (including electricity) or services at the
          end of the fixed-term, subject to the agreed termination rules and a period of notice that
          does not exceed one month.
        </Trans>
      </li>
      <li>
        <Trans>
          With respect to contracts as described in the first two paragraphs, the consumer can:
        </Trans>
        <ul>
          <li>
            <Trans>
              terminate them at all times and not be limited to termination at a specific time or
              during a specific period;
            </Trans>
          </li>
          <li>
            <Trans>terminate them in the same way as that in which they were concluded;</Trans>
          </li>
          <li>
            <Trans>
              always terminate them subject to the same period of notice as that stipulated for the
              trader.
            </Trans>
          </li>
        </ul>
      </li>
    </ol>
    <p className="italic text-gray-700">
      <Trans>Prolongation</Trans>
    </p>
    <ol className="list-decimal pl-6 text-gray-700">
      <li>
        <Trans>
          A fixed-term contract that was concluded for the regular supply of products (including
          electricity) or services may not be automatically prolonged or renewed for a fixed period
          of time.
        </Trans>
      </li>
      <li>
        <Trans>
          In departure from that which is stated in the previous paragraph, a fixed-term contract
          that has been concluded for the regular supply of daily or weekly newspapers or magazines
          may be automatically prolonged for a fixed term that does not exceed three months, if the
          consumer is at liberty to terminate this prolonged contract towards the end of the
          prolongation, with a period of notice that does not exceed one month.
        </Trans>
      </li>
      <li>
        <Trans>
          A fixed-term contract that has been concluded for the regular supply of products or
          services may only be automatically prolonged for an indefinite period of time if the
          consumer has at all times the right to terminate, with a period of notice that does not
          exceed one month and, in the case of a contract to supply daily or weekly newspapers or
          magazines regularly but less than once per month, a period that does not exceed three
          months.
        </Trans>
      </li>
      <li>
        <Trans>
          A fixed-term contract for the regular supply, by way of introduction, of daily or weekly
          newspapers and magazines (trial subscriptions or introductory subscriptions) will not be
          automatically prolonged and will automatically terminate at the end of the trial period or
          introductory period.
        </Trans>
      </li>
    </ol>
    <p className="italic text-gray-700">
      <Trans>Duration</Trans>
    </p>
    <ol className="list-decimal pl-6 text-gray-700">
      <li>
        <Trans>
          If the fixed-term of a contract exceeds one year, then after one year the consumer has at
          all times a right to terminate, with a period of notice that does not exceed one month,
          unless reasonableness and fairness dictate that premature termination of the contract
          would be unacceptable.
        </Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 15 – Payment</Trans>
    </h3>
    <ol className="list-decimal pl-6 text-gray-700">
      <li>
        <Trans>
          As far as no other date is stipulated in the contract or supplementary conditions, sums
          payable by the consumer should be paid within 14 days after commencement of the withdrawal
          period, or in the absence of a withdrawal period within 14 days after the conclusion of
          the contract. In the case of a contract to provide a service, this 14-day period starts on
          the day after the consumer received confirmation of the contract.
        </Trans>
      </li>
      <li>
        <Trans>
          When selling products to consumers, the general terms and conditions may never stipulate
          an advance payment in excess of 50%. Where advance payment is stipulated, the consumer
          cannot invoke any rights whatsoever in relation to the implementation of the order or
          service(s) in question before the stipulated advance payment has been made.
        </Trans>
      </li>
      <li>
        <Trans>
          The consumer is obliged to report immediately to the trader any inaccuracies in payment
          data provided or stated.
        </Trans>
      </li>
      <li>
        <Trans>
          If a consumer fails to fulfil his payment obligation(s) in good time, after the trader has
          informed the consumer about the late payment, the consumer is allowed 14 days in which to
          fulfil the obligation to pay; if payment is not made within this 14-day period, statutory
          interest will be payable over the sum owed and the trader has the right to charge
          reasonable extrajudicial costs of collection he has incurred. These costs of collection
          amount to, at the most: 15% of unpaid sums up to €2,500; 10% over the next €2,500; and 5%
          over the next €5,000, with a minimum of €40. The trader can make departures from these
          sums and percentages that are favorable to the consumer.
        </Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 16 – Complaints procedure</Trans>
    </h3>
    <ol className="list-decimal pl-6 text-gray-700">
      <li>
        <Trans>
          The trader provides for a complaints procedure, that has been given sufficient publicity,
          and will deal with a complaint in accordance with this complaints procedure.
        </Trans>
      </li>
      <li>
        <Trans>
          A consumer who has discovered shortcomings in the implementation of a contract must submit
          any complaints to the trader without delay, in full and with clear descriptions.
        </Trans>
      </li>
      <li>
        <Trans>
          A reply to complaints submitted to the trader will be provided within a period of 14 days,
          calculated from the date of receipt. If it is anticipated that a complaint will require a
          longer processing time, then the trader will reply within 14 days, confirming receipt and
          indicating when the consumer can expect a more elaborate reply.
        </Trans>
      </li>
      <li>
        {/* eslint-disable-next-line lingui/text-restrictions */}
        <Trans>
          A complaint about a product, a service or the trader’s service can also be submitted via a
          complaints form on the consumer’s page of the website of Thuiswinkel.org,
          www.thuiswinkel.org. The complaint is then sent both to the trader concerned and
          Thuiswinkel.org.
        </Trans>
      </li>
      <li>
        <Trans>
          The consumer should give the trader a time period of at least 4 weeks to solve the
          complaint in joint consultation. After this period of time, the complaint becomes a
          dispute that is subject to the disputes settlement scheme.
        </Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 17 – Disputes</Trans>
    </h3>
    <ol className="list-decimal pl-6 text-gray-700">
      <li>
        <Trans>
          Contracts entered into between a trader and a consumer and which are subject to these
          general terms and conditions are subject only to Dutch law.
        </Trans>
      </li>
      <li>
        <Trans>
          Disputes between a consumer and a trader over the conclusion or exercising of contracts
          relating to products and services to be supplied by this trader can be put before the
          Thuiswinkel Disputes Committee, P.O. Box 90600, 2509 LP in The Hague (www.sgc.nl), by
          either the consumer or the trader, with due observance of that which is stipulated below.
        </Trans>
      </li>
      <li>
        <Trans>
          The Disputes Committee will only deal with a dispute if the consumer first put his
          complaint, without delay, to the trader.
        </Trans>
      </li>
      <li>
        <Trans>
          If the complaint does not lead to a solution, the dispute should be submitted to the
          Disputes Committee no later than 12 months after the consumer submitted the complaint to
          the trader.
        </Trans>
      </li>
      <li>
        <Trans>
          If a consumer wants to put a dispute before the Disputes Committee, the trader is bound by
          that choice. Preferably, the consumer notifies the trader first. If a trader wishes to put
          a dispute before the Disputes Committee, then the consumer will indicate, in response to a
          written request made by the trader, whether he is in contract, or prefers the dispute to
          be dealt with by the competent law-court. If the consumer does not indicate his choice to
          the trader within a period of five weeks, then the trader has a right to put the dispute
          before the competent law-court.
        </Trans>
      </li>
      <li>
        <Trans>
          Rulings of the Disputes Committee are subject to the conditions as stipulated in the
          regulations of the Disputes Committee. Decisions of the Disputes Committee take the form
          of binding advice.
        </Trans>
      </li>
      <li>
        <Trans>
          The Disputes Committee will not deal with a dispute – or will terminate their intervention
          – if the trader has been granted a suspension of payments, gone bankrupt or has actually
          terminated business activities before the committee dealt with the dispute during a
          session and rendered a final ruling.
        </Trans>
      </li>
      <li>
        <Trans>
          If, alongside the Thuiswinkel Disputes Committee, there is another competent disputes
          committee that is recognized or one that is affiliated with the Disputes Committee
          Foundation for Consumer Affairs (SGC) or the Complaints Institute Financial Services
          (Kifid), the Thuiswinkel Disputes Committee shall preferably have jurisdiction for
          disputes that relate principally to the sales method or to providing services
          long-distance. For all other disputes, this will be the other disputes committee
          recognized by the SGC or affiliated with the Kifid.
        </Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 18 – Branch guarantee</Trans>
    </h3>
    <ol className="list-decimal pl-6 text-gray-700">
      <li>
        <Trans>
          Thuiswinkel.org guarantees the fulfilment of obligations of her members in relation to
          binding advices imposed on them by the Thuiswinkel Disputes Committee, unless the member
          decides to put the binding advice before a law-court for verification within two months
          after the date of that advice. In case of law-court verification the suspension of the
          guarantee will end and the guarantee will come into effect again upon the court ruling
          becoming final and conclusive, whereby the court has declared that the binding advice has
          binding effect. Up to a maximum sum of € 10,000 per binding advice Thuiswinkel.org will
          pay this sum to the consumer. In case of sums higher than € 10,000 per binding advice, the
          sum of € 10,000 will be paid. As far as the sum is higher than € 10,000 Thuiswinkel.org
          has the obligation to take reasonable adequate efforts to pursue the member to fulfil the
          binding advice.
        </Trans>
      </li>
      <li>
        <Trans>
          Application of this guarantee requires the consumer to submit a written appeal to
          Thuiswinkel.org and to transfer his claim on the trader to Thuiswinkel.org. In as far as
          the claim on the trader exceeds the sum of € 10,000, the consumer will be offered the
          possibility to transfer his claim on the trader above the sum of € 10,000 to
          Thuiswinkel.org, where after this organization will pursue payment of the claim in court
          on her own title and costs.
        </Trans>
      </li>
    </ol>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 19 Additional or different stipulations</Trans>
    </h3>
    <p className="text-gray-700">
      <Trans>
        Additional stipulations or stipulations that differ from these general terms and conditions,
        may not be detrimental to the consumer and should be recorded in writing, or in such a way
        that consumers can store them in a readily accessible manner on a durable medium.
      </Trans>
    </p>

    <h3 className="mt-4 text-lg font-semibold text-[#0182A9]">
      <Trans>Article 20 – Amendment to the general terms and conditions of Thuiswinkel</Trans>
    </h3>
    <ol className="list-decimal pl-6 text-gray-700">
      <li>
        {/* eslint-disable-next-line lingui/text-restrictions */}
        <Trans>
          Thuiswinkel.org will only amend these general terms and conditions after consultation with
          the Consumers’ Association.
        </Trans>
      </li>
      <li>
        <Trans>
          Amendments to these terms and conditions will only come into effect after they have been
          published in the appropriate way, on the understanding that where amendments apply during
          the validity of an offer, the stipulation that is most favorable to the consumer will
          prevail.
        </Trans>
      </li>
    </ol>

    <h2 className="mt-4 text-xl font-semibold text-[#0182A9]">
      <Trans>Thuiswinkel.org</Trans>
    </h2>
    <p className="text-gray-700">
      <Trans>P.O. Box 7001, 6710 CB EDE, the Netherlands.</Trans>
    </p>
    <p className="text-gray-700">
      <Trans>
        Rights can only be derived on the basis of the Dutch version of these general terms and
        conditions.
      </Trans>
    </p>
  </div>
);

export default TermsAndConditions;
