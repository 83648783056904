import { Trans } from "@lingui/macro";
import React, { useState } from "react";

const ModalComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("resume");

  const openModalWithTab = (tabId: string) => {
    setActiveTab(tabId);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // Tab data array with id
  const tabData = [
    {
      id: "resume",
      name: <Trans>Resume</Trans>,
      title: (
        <>
          <div className="mb-2 text-[40px] font-black text-[#111928]">
            <Trans>Ready to </Trans>{" "}
            <span className="bg-gradient-to-l from-[#FFEFDA] to-[#DB7B03] bg-clip-text text-transparent">
              <Trans>Land your Dream Job?</Trans>
            </span>
          </div>
          <div className="text-[40px] font-black text-[#111928]">
            <Trans>Try out our </Trans>{" "}
            <span className="bg-gradient-to-l from-[#FFEFDA] to-[#DB7B03] bg-clip-text text-transparent">
              <Trans>resume builder.</Trans>
            </span>
          </div>
        </>
      ),
      firstParagraph: (
        <Trans>
          It makes it simple to create a professional CV that stands out! Tailor your resume in
          minutes with expert tips and templates designed to showcase your unique strengths.
        </Trans>
      ),
      secondParagraph: (
        <Trans>Take the next step toward your career goals – start building your resume now!</Trans>
      ),
      img: "/landing/resume-left-image.png",
    },
    {
      id: "cover-letters",
      name: <Trans>Cover Letter </Trans>,
      title: (
        <>
          <div className="mb-2 text-[40px] font-black text-[#111928]">
            <Trans>Hard to </Trans>{" "}
            <span className="bg-gradient-to-l from-[#FFEFDA] to-[#DB7B03] bg-clip-text text-transparent">
              <Trans>communicate?</Trans>
            </span>
          </div>
          <div className="text-[40px] font-black text-[#111928]">
            <Trans>Try out our </Trans>{" "}
            <span className="bg-gradient-to-l from-[#FFEFDA] to-[#DB7B03] bg-clip-text text-transparent">
              <Trans>Cover Letters.</Trans>
            </span>
          </div>
        </>
      ),
      firstParagraph: (
        <Trans>
          It makes it simple to create a professional CV that stands out! Tailor your resume in
          minutes with expert tips and templates designed to showcase your unique strengths.
        </Trans>
      ),
      secondParagraph: (
        <Trans>Take the next step toward your career goals – start building your resume now!</Trans>
      ),
      img: "/landing/resume-left-image.png",
    },
    {
      id: "personality-snapshots",
      name: <Trans>Personality Snapshots</Trans>,
      title: (
        <div className="mb-2 text-[40px] font-black text-[#111928]">
          <span className="bg-gradient-to-l from-[#FFEFDA] to-[#DB7B03] bg-clip-text text-transparent">
            <Trans>Personality Snapshots</Trans>
          </span>{" "}
          <Trans>is the way to </Trans>{" "}
          <span className="bg-gradient-to-l from-[#FFEFDA] to-[#DB7B03] bg-clip-text text-transparent">
            <Trans>analyze yourself.</Trans>
          </span>
        </div>
      ),
      firstParagraph: (
        <Trans>
          It makes it simple to create a professional CV that stands out! Tailor your resume in
          minutes with expert tips and templates designed to showcase your unique strengths.
        </Trans>
      ),
      secondParagraph: (
        <Trans>Take the next step toward your career goals – start building your resume now!</Trans>
      ),
      img: "/landing/resume-left-image.png",
    },
  ];

  // Find active tab data based on the id
  const activeTabData = tabData.find((tab) => tab.id === activeTab);

  return (
    <div>
      {/* Three buttons fixed to the right side of the screen */}
      <button
        className="fixed right-0 top-1/3 z-50 mb-2 -translate-y-1/2 rounded-l-[18px] bg-gradient-to-br from-[#DB7B03] to-[#FFEFDA] p-[18px] text-white opacity-80 shadow-lg md:p-[36px]"
        onClick={() => {
          openModalWithTab("resume");
        }}
      >
        <img src="/landing/resume-icon.svg" alt="resume tab" width="18px" className="md:w-[36px]" />
      </button>
      <button
        className="fixed right-0 top-1/2 z-50 mb-2 -translate-y-1/2 rounded-l-[18px] bg-gradient-to-br from-[#DB7B03] to-[#FFEFDA] p-[18px] text-white opacity-80 shadow-lg md:p-[36px]"
        onClick={() => {
          openModalWithTab("cover-letters");
        }}
      >
        <img
          src="/landing/ph_envelope-bold.svg"
          alt="cover letter tab"
          width="18px"
          className="md:w-[36px]"
        />
      </button>
      <button
        className="fixed right-0 top-2/3 z-50 -translate-y-1/2 rounded-l-[18px] bg-gradient-to-br from-[#DB7B03] to-[#FFEFDA] p-[18px] text-white opacity-80 shadow-lg md:p-[36px]"
        onClick={() => {
          openModalWithTab("personality-snapshots");
        }}
      >
        <img
          src="/landing/ph_person-arms-spread-bold.svg"
          alt="personality snapshot tab"
          width="18px"
          className="md:w-[36px]"
        />
      </button>

      {/* Modal overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800"
          style={{ backgroundColor: "rgba(31, 41, 55, 0.5)" }}
          onClick={closeModal}
        >
          {/* Modal content */}
          <div
            className="relative flex w-full max-w-6xl rounded-r-3xl bg-white shadow-lg"
            onClick={(e) => {
              e.stopPropagation();
            }} // Prevent closing on content click
          >
            {/* Left Side Image */}
            <div className="h-full w-3/4 max-md:hidden">
              <img
                src={activeTabData?.img}
                alt="resume builder"
                className="size-full object-cover object-top"
              />
            </div>

            {/* Right Side Content */}
            <div className="flex flex-col justify-center p-8 pl-10">
              {/* Close Button */}
              <button
                className="absolute right-4 top-4 text-gray-600 hover:text-gray-900"
                onClick={closeModal}
              >
                <img src="/landing/basil_cross-solid.svg" alt="close" width="60px" />
              </button>

              {/* Horizontal Tab Switcher */}
              <div className="mb-4 flex max-w-fit justify-center self-start rounded-[12px] border border-[#B0B0B0]">
                {tabData.map((tab) => (
                  <button
                    key={tab.id} // Use id as the key
                    className={`px-[12px] py-[16px] ${
                      activeTab === tab.id ? "bg-[#0182A9] text-white" : "bg-white text-[#626262]"
                    } rounded-[12px] text-[16px]  font-bold`}
                    onClick={() => {
                      setActiveTab(tab.id); // Set active tab by id
                    }}
                  >
                    {tab.name} {/* Keep the name for display */}
                  </button>
                ))}
              </div>

              {/* Content for active tab */}
              <div className="mb-4" style={{ height: "250px", overflowY: "auto" }}>
                <h2 className="mb-5 text-4xl font-semibold">{activeTabData?.title}</h2>
                <p className="text-[20px]">{activeTabData?.firstParagraph}</p>
                <p className="mt-10 text-[20px]">{activeTabData?.secondParagraph}</p>
              </div>

              {/* Start Building Button */}
              <button className="mt-4 max-w-fit rounded bg-[#0182A9] px-[20px] py-[12px] text-[16px] text-white shadow-lg">
                <a href={`/dashboard/${activeTabData?.id}`}>
                  {" "}
                  <Trans> Start Building My</Trans> {activeTabData?.name}
                </a>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalComponent;
